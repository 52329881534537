/* .imgbox .img:hover
{
    opacity: .6;
}
.imgbox:hover .img
{
    opacity: .6;
} */
/*   
.hover14 figure {
	position: relative;
}
.hover14 figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.hover14 figure:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
} */

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease;
}

.image-container:hover img {
  transform: scale(1.1);
}
.slider_section {
  background: rgba(34, 139, 172, 0.281);
}
.loader-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.loader-overlay1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}
.css-1nmdiq5-menu {
  z-index: 1000 !important;
}
.category-item {
  height: 180px !important;
  width: 350px !important;
}
.galleryImg {
  height: 180px !important;
  width: 350px !important;
}
.cat__img {
  object-fit: contain !important;
  background: #f8f9fa;
}
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-icon {
  color: #84a9f7; /* Customize icon color as needed */
  margin-left: 8px;
}

.tooltip-text {
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -125px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.category-item::after{
  /* visibility: hidden !important; */
  background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)) !important;
}
.text-dark{
  color: black !important;
}
.custom-loader{
  width: 20px;
  height:20px;
  margin-left: 10px;
  padding: 2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}
