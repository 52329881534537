.policy-page {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.8;
  margin: 20px;
  color: #333;
  background-color: #f9f9f9;
  padding: 20px;
  min-height: 100vh;
}

.policy-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2, h3, h4 {
  color: rgb(4, 63, 126);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 15px;
}

h4 {
  font-size: 1.25rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.section {
  margin-bottom: 25px;
}

.sub-section {
  margin-left: 20px;
}

.sub-section ul {
  margin-left: 25px;
  list-style-type: disc;
}

.contact {
  margin-top: 30px;
}

.effective-date {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}

.text-primary {
  color: #007bff;
  text-decoration: none;
}

.text-primary:hover {
  text-decoration: underline;
}

.back-button {
  display: block;
  margin: 30px auto 0;
  padding: 10px 20px;
  background-color: rgb(4, 63, 126);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.back-button:hover {
  background-color: rgb(3, 50, 100);
}

.acknowledgment {
  border-top: 1px solid #eee;
}